<template>
  <img 
    class="sam-image" 
    :class="additionalClass" 
    :src="emptyImage ? require('@/assets/images/product-photo-coming-soon-small.jpg') : imageUrl"
    />
</template>

<script>
export default {
  name: 'SamImage',
  data() {
    return {
      productImage: "@/assets/images/product-photo-coming-soon-small.jpg"
    }
  },
  props: {
    imageUrl: {
      type: String,
      default: ''
    },
    additionalClass: {
        type: String,
        default: ''
    },
    mobileView: {
      type: Boolean,
      default: false
    },
    emptyImage: {
      type: Boolean,
      default: false
    },
  }
}
</script>
<style lang="scss" scoped>
  .sam-image {
    display: block;
    max-width: 100%;
    border-radius: $mainRadius;
  }

  .tableItem {
    margin-left: 7px;
  }
</style>