<!-- ChildComponent.vue -->
<template>
  <div>
    <div v-for="item in rates" v-bind:key="item.rate_id">
      <SamTableRow hoverable noSpace clickableRow class="select-shipping">
        <SamTableColumn class="sam-table-column">
          <div class="row">
            <div class="col-sm-2 col-2 p-0">
              <div class="sam-radio-form-group form-check">
                <input type="radio" v-model="selectedRateItem" :value="item.rate_id" @change="handleSelection(item)"
                       class="sam-input sam-radio float-start align-middle mt-3">
              </div>
            </div>
            <div class="col-sm-2 col-2 p-0">
              <SamImage :imageUrl="`${carrierLogo(item.carrier_label)}`"/>
            </div>

            <div class="col-sm-6 col-2 p-0">
              <SamParagraph :text="`${carrierName(item.carrier_label)}`" bolded tableItem/>
              <SamParagraph :text="item.service" tableItem/>
            </div>

          </div>
        </SamTableColumn>


        <SamTableColumn :mobileColumn="windowWidth < mobileView" class="sam-table-column pb-2">
          <SamParagraph text="For product(s)" tableItem bolded/>
          <SamParagraph :text="parcelData[0]['ContentDescription']" tableItem/>
        </SamTableColumn>

        <SamTableColumn :mobileColumn="windowWidth < mobileView" class="sam-table-column bp-2">
          <SamParagraph text="Estimated delivery date" tableItem bolded/>
          <SamParagraph :text="`${calculateEstimatedDays(item.delivery_days)}`" tableItem/>
        </SamTableColumn>
        <!--NEEDED LATER MAYBE <SamTableColumn :mobileColumn="windowWidth < mobileView" class="sam-table-column">
          <SamParagraph text="Estimated delivery costs" tableItem bolded/>
          <SamParagraph :text="String(item.rate) + ' ' + `${item.currency}`" tableItem/>
        </SamTableColumn>-->


        <!-- <SamTableRow hoverable noSpace clickableRow class="select-shipping"> -->

        <!-- <SamTableColumn :mobileColumn="windowWidth < mobileView"
                        class="sam-table-column col-sm-12 col-md-3 col-6 tableView"> -->
        <!--<SamTableColumn class="sam-table-column col-sm-12 col-md-3 col-6 tableView">
          <SamTableRow>
            <SamTableColumn class="col-md-1 p-0" style="background-color: yellow">
              <div class="sam-radio-form-group form-check">
                <input type="radio" v-model="selectedRateId" :value="item.rate_id" @change="handleSelection(item)"
                       class="sam-input sam-radio float-start align-middle">
              </div>
            </SamTableColumn>
            <SamTableColumn class="col-md-1 p-0">
              <SamImage :imageUrl="`${getCarrierLogobyLabel(item.carrier_label)}`"/>
            </SamTableColumn>
            <SamTableColumn class="col-md-10 p-0">
              <SamTableRow>
                <SamTableColumn class="col-md-12 p-0">
              <SamParagraph :text="`${getCarrierNamebyLabel(item.carrier_label)}`" bolded tableItem/>
              <SamParagraph :text="item.service" tableItem/>
                  </SamTableColumn>
                </SamTableRow>
            </SamTableColumn>
          </SamTableRow>
        </SamTableColumn>-->
        <!-- <SamTableColumn class="sam-table-column col-sm-12 col-md-3 col-6 tableView">
           <SamTableRow>
             <SamTableColumn class="col-1 col-md-1 p-0">

               <div class="sam-radio-form-group form-check">
                 <input type="radio" v-model="selectedRateId" :value="item.rate_id" @change="handleSelection(item)"
                        class="sam-input sam-radio float-start align-middle">
               </div>
             </SamTableColumn>
             <SamTableColumn class="col-2 col-md-2 p-0">
               <SamImage :imageUrl="`${getCarrierLogobyLabel(item.carrier_label)}`"/>
             </SamTableColumn>
             <SamTableColumn class="col-8 col-md-8 p-0">
               <SamParagraph :text="`${getCarrierNamebyLabel(item.carrier_label)}`" bolded tableItem/>
               <SamParagraph :text="item.service" tableItem/>
             </SamTableColumn>
           </SamTableRow>
         </SamTableColumn>
           -->
        <!-- </SamTableColumn> -->
        <!-- <SamTableColumn :mobileColumn="windowWidth < mobileView"
                         class="sam-table-column col-sm-12 col-md-3 col-6 tableView">
           <SamParagraph text="For product(s)" tableItem bolded/>
           <SamParagraph :text="parcelData[0]['ContentDescription']" tableItem/>
         </SamTableColumn>

         <SamTableColumn :mobileColumn="windowWidth < mobileView"
                         class="sam-table-column col-sm-12 col-md-3 col-6 tableView">
           <SamParagraph text="Estimated delivery date" tableItem bolded/>
           <SamParagraph :text="`${calculateEstimatedDays(item.delivery_days)}`" tableItem/>
         </SamTableColumn>
         <SamTableColumn :mobileColumn="windowWidth < mobileView"
                         class="sam-table-column col-sm-12 col-md-3 col-6 tableView">
           <SamParagraph text="Estimated delivery costs" tableItem bolded/>
           <SamParagraph :text="String(item.rate) + ' ' + `${item.currency}`" tableItem/>
         </SamTableColumn>-->
      </SamTableRow>
    </div>
  </div>
</template>
<script>
import SamTableRow from "@/components/table/SamTableRow.vue";
import SamParagraph from "@/components/SamParagraph.vue";
import SamTableColumn from "@/components/table/SamTableColumn.vue";
import SamImage from '@/components/SamOneColumnImage.vue';

export default {
  name: 'ShippingSelector',
  components: {SamTableColumn, SamParagraph, SamTableRow, SamImage},
  data() {
    return {
      rates: [],
      selectedRateId: '',
      selectedRateItem: '',
      carriersInfo: null,
      env: process.env,
      mobileView: 768,
      tabletView: 1044,
      windowWidth: document.documentElement.clientWidth,
    }
  },
  props: {
    address: {
      type: Object,
      required: true
    },
    parcelData: {
      type: Array,
      required: true
    },
    supplierId: {
      type: String,
      required: true
    }
  },
  mounted() {
    if (this.rates.length > 0) {
      // Set the initial selectedRateId to the rate_id of the first item
      this.selectedRateId = this.rates[0].rate_id;
      this.selectedRateItem = this.rates[0].rate_id;
    }
  },
  computed: {},
  watch: {
    parcelData: async function (newData, oldData) {
      if (newData !== oldData) {
        await this.fetchCarrierData(newData[0])

      }
    },
    rates: {
      deep: true,
      immediate: true,
      handler() {
        // Handle changes in rates
        this.setSelectedRateId();
      },
    },
  },
  async created() {
    this.carriersInfo = await this.fetchCarriersInfo();
    await this.fetchCarrierData(this.parcelData[0])
  },
  methods: {
    setSelectedRateId() {
      if (this.rates.length > 0 && !this.selectedRateId) {
        this.parcelData[0]["selectedRate"] = this.rates[0]
        this.selectedRateId = this.rates[0].rate_id;
        this.selectedRateItem = this.rates[0].rate_id;
        this.$emit('selected-rate', this.parcelData[0], this.supplierId);
        this.selectedRateId = ''
      }
    },
    handleSelection(selectedRate) {
      this.parcelData[0]["selectedRate"] = selectedRate
      this.$emit('selected-rate', this.parcelData[0], this.supplierId);
    },
    async fetchCarrierData(data) {
      try {
        this.$emit('reset-rates-fetched')
        await this.$store.dispatch('admin/fetchShippingRates', data)
        const rates = await this.$store.getters['admin/shippingRates'];
        if (rates.length !== 0) {
          this.rates = rates;
        }
      } catch (error) {
        //console.log("ERROR: ", error)
      }
      if (this.rates.hasOwnProperty('error_message')) {
        this.$emit('api-finished', 400, this.rates.error_message);
      } else {
        this.$emit('api-finished', this.rates.length === 0 ? false : true);
      }

    },
    async fetchCarriersInfo() {
      try {
        let carriers = await this.getCarrierInfo();
        return carriers
      } catch (e) {
        return null
      }
    },
    async getCarrierInfo() {
      if (Object.keys(this.$store.getters.carriersInfo).length === 0) {
        this.$store.dispatch('fetchCarriers');
        await this.$store.dispatch('fetchStoreCarriers');
      }
      const carrier = this.$store.getters.carriersInfo
      return carrier
    },
    carrierName(label) {
      if (!this.carriersInfo) {
        return ''
      } else {
        const carrier = this.carriersInfo[label]
        return carrier ? carrier.name : ''
      }
    },
    carrierLogo(label) {
      if (!this.carriersInfo) {
        return ''
      } else {
        const carrier = this.carriersInfo[label]
        return carrier ? this.env.VUE_APP_IMAGE_HOST + carrier.logo : ''
      }
    },
    calculateEstimatedDays(value) {
      const currentDate = new Date();
      if (typeof value === 'number') {
        currentDate.setDate(currentDate.getDate() + 1);
      } else if (typeof value === 'string') {
        const hyphenIndex = value.indexOf('-');
        if (hyphenIndex !== -1) {
          const days = parseInt(value.slice(hyphenIndex + 1), 10);
          if (!isNaN(days)) {
            currentDate.setDate(currentDate.getDate() + days);
          }
        }
      }
      const formattedDate = currentDate.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
      return formattedDate;
    },
  }
}
</script>
<style lang="scss" scoped>

.sam-radio-form-group {
  display: flex;
  align-items: center;
}

.sam-radio {
  margin-right: 0.5rem;
  width: 1rem;
  height: 1rem;
  position: relative;
  cursor: pointer;
  display: block;
  bottom: 1px;
}

.sam-radio:disabled {
  opacity: 0.5;
}

.sam-radio-label.disabled {
  opacity: 0.5;
}

.sam-radio-form-group input:checked {
  accent-color: $almostBlack;
}

.sam-radio-label {
  font-weight: $mainFontWeight;
  font-size: $mainFontSize;
}

.sam-label {
  margin: 0;
}

.select-shipping img {
  max-width: 48px;
  margin-right: 7px;
}

</style>
